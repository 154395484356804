/*--

TEMPLATE NAME: Sinewave - One Page Hosting Landing Page HTML Template
TEMPLATE URI: - http://froid.works/themeforest/sinewave/resource/
DESCRIPTION:  Hosting HTML Template
VERSION: 2.0
AUTHOR: Ajay Kumar Choudhary
AUTHOR URL: http://codecanyon.net/user/ajay138/
DESIGN BY: Rakesh Kumar

[TABLE OF CONTENTS]

1.0 BUTTONS
2.0 ALERTS
3.0 FORM ELEMENTS
4.0 MENU
5.0 CUSTOM ICONS
6.0 DEFAULT PAGE STYLING
7.0 GENERAL RESET

--*/
/* Buttons */
.btn {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  transition: all 0.5s ease;
  text-transform: uppercase;
  border-radius: 0;
  border-top-right-radius: 10px;
}

.btn span {
  display: block;
}

.btn:focus {
  outline: none;
}

.btn.active, .btn:active {
  box-shadow: none;
}

.btn-fill {
  background: #24aa65;
  border: 2px solid #24aa65;
}

.btn-fill:hover, .btn-fill:focus {
  background: transparent;
  color: #24aa65;
}

.btn-bordered {
  background: transparent;
  border: 2px solid #ffffff;
}

.btn-bordered:hover, .btn-bordered:focus {
  background: #ffffff;
  color: #24aa65;
}

.btn-black-bordered {
  color: #444444;
  background: transparent;
  border: 2px solid #444444;
}

.btn-black-bordered:hover, .btn-black-bordered:focus {
  background: #444444;
  color: #ffffff;
}

.btn-green-bordered {
  color: #2bbf71;
  background: transparent;
  border: 2px solid #2bbf71;
}

.btn-green-bordered:hover, .btn-green-bordered:focus {
  outline: unset;
  background: #2bbf71;
  color: #ffffff;
}

.btn-xs {
  font-size: 12px;
  padding: 6px 24px;
}

.btn-sm {
  padding: 6px 10px;
}

.btn-md {
  padding: 10px 30px;
}

.btn-lg {
  padding: 15px 25px;
  font-size: 16px;
}

.btn-rounded {
  border-radius: 50px;
}

.btn-success {
  background: #00caa4;
  border: 2px solid #00caa4;
}

.btn-success:hover, .btn-success:focus {
  background: transparent;
  color: #00caa4;
  border-color: #00caa4;
}

.btn-info {
  background: #59b2c9;
  border: 2px solid #59b2c9;
}

.btn-info:hover, .btn-info:focus {
  background: transparent;
  color: #59b2c9;
  border-color: #59b2c9;
}

.btn-warning {
  background: #ffd361;
  border: 2px solid #ffd361;
}

.btn-warning:hover, .btn-warning:focus {
  background: transparent;
  color: #ffd361;
  border-color: #ffd361;
}

.btn-danger {
  background: #ff5a5a;
  border: 2px solid #ff5a5a;
}

.btn-danger:hover, .btn-danger:focus {
  background: transparent;
  color: #ff5a5a;
  border-color: #ff5a5a;
}

/* ALERT BOX RESET */
.alert {
  padding: 10px 15px 10px 40px;
  border-radius: 3px;
  position: relative;
  text-align: left;
  letter-spacing: 1px;
}

.alert:before {
  font-family: FontAwesome;
  font-size: 15px;
  position: absolute;
  left: 15px;
}

.alert.alert-info {
  color: #ffffff;
  background-color: #59b2c9;
  border-color: #59b2c9;
}

.alert.alert-info:before {
  content: "\f05a";
  color: rgba(76, 76, 76, 0.6);
}

.alert.alert-warning {
  color: #6b6b6b;
  background-color: #ffd361;
  border-color: #ffd361;
}

.alert.alert-warning:before {
  content: "\f071";
  color: rgba(76, 76, 76, 0.6);
}

.alert.alert-danger {
  color: #ffffff;
  background-color: #ff5a5a;
  border-color: #ff5a5a;
}

.alert.alert-danger:before {
  content: "\f05e";
  color: rgba(76, 76, 76, 0.6);
}

.alert.alert-success {
  color: #f7f7f7;
  background-color: #00caa4;
  border-color: #00caa4;
}

.alert.alert-success:before {
  content: "\f058";
  color: rgba(76, 76, 76, 0.6);
}

/* Form Elements Start */
.input {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 16px;
  max-width: 400px;
  width: calc(100% - 32px);
  vertical-align: top;
}

.input__field {
  position: relative;
  display: block;
  float: right;
  padding: 15px;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-family: "Open Sans", sans-serif;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
}

.input__field:focus {
  outline: none;
}

.input__label {
  display: inline-block;
  float: right;
  /*padding: 0 16px;*/
  width: 100%;
  color: #6a7989;
  font-weight: bold;
  font-size: 100.25%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__label-content {
  position: relative;
  display: block;
  padding: 0 0 25px 0;
  width: 100%;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.graphic {
  position: absolute;
  top: 0;
  left: 0;
  fill: none;
}

.icon {
  color: #ddd;
  font-size: 150%;
}

/* Madoka */
.input--madoka {
  margin: 20px 0 25px 0;
}

.input__field--madoka {
  width: 100%;
  background: transparent;
  color: #fff;
}

.input__label--madoka {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: left;
  cursor: text;
}

.input__label-content--madoka {
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.graphic--madoka {
  -webkit-transform: scale3d(1, -1, 1);
  transform: scale3d(1, -1, 1);
  -webkit-transition: stroke-dashoffset 0.3s;
  transition: stroke-dashoffset 0.3s;
  pointer-events: none;
  stroke: #fff;
  stroke-width: 4px;
  stroke-dasharray: 962;
  stroke-dashoffset: 558;
}

.input__field--madoka:focus + .input__label--madoka,
.input--filled .input__label--madoka {
  cursor: default;
  pointer-events: none;
}

.input__field--madoka:focus + .input__label--madoka .graphic--madoka,
.input--filled .graphic--madoka {
  stroke-dashoffset: 0;
}

.input__field--madoka:focus + .input__label--madoka .input__label-content--madoka,
.input--filled .input__label-content--madoka {
  -webkit-transform: scale3d(0.81, 0.81, 1) translate3d(0, 64px, 0);
  transform: scale3d(0.81, 0.81, 1) translate3d(0, 64px, 0);
}

.input__field--madoka:focus + .input__label--madoka .input__label-content--madoka.text-area,
.input--filled .input__label-content--madoka.text-area {
  -webkit-transform: scale3d(0.81, 0.81, 1) translate3d(0, 90px, 0);
  transform: scale3d(0.81, 0.81, 1) translate3d(0, 90px, 0);
  stroke-width: 2px;
}

/* Form Elements End */
/* Menu Start */
#view-code {
  color: #4183d7;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  position: absolute;
  top: 640px;
  left: 50%;
  margin-left: -35px;
}

div.screen {
  width: 320px;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.list {
  margin-top: 36px;
  text-align: left;
}

div.burger {
  height: 25px;
  width: 40px;
  position: absolute;
  top: 0;
  cursor: pointer;
}

div.x, div.y, div.z {
  position: absolute;
  margin: auto;
  top: 0px;
  bottom: 0px;
  background: #fff;
  border-radius: 2px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}

div.x, div.y, div.z {
  height: 3px;
  width: 26px;
}

div.y {
  top: 18px;
}

div.z {
  top: 37px;
}

div.reset-bar {
  top: 20px;
  -webkit-transition: all 70ms ease-out;
  -moz-transition: all 70ms ease-out;
  -ms-transition: all 70ms ease-out;
  -o-transition: all 70ms ease-out;
  transition: all 70ms ease-out;
}

div.rotate30 {
  -ms-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-transition: all 50ms ease-out;
  -moz-transition: all 50ms ease-out;
  -ms-transition: all 50ms ease-out;
  -o-transition: all 50ms ease-out;
  transition: all 50ms ease-out;
}

div.rotate150 {
  -ms-transform: rotate(150deg);
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-transition: all 50ms ease-out;
  -moz-transition: all 50ms ease-out;
  -ms-transition: all 50ms ease-out;
  -o-transition: all 50ms ease-out;
  transition: all 50ms ease-out;
}

div.rotate45 {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -ms-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

div.rotate135 {
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transition: all 100ms ease-out;
  -moz-transition: all 100ms ease-out;
  -ms-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

div.navbar {
  height: 73px;
  background: #385e97;
}

div.menu-bg {
  width: 320px;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: #40435b;
  opacity: 0;
  -webkit-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1);
}

div.menu-bg.animate {
  opacity: 0.9;
  -webkit-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 400ms cubic-bezier(0, 0.995, 0.99, 1);
}

div.menu {
  height: 100vh;
  width: 100%;
}

.menu-splitL, .menu-splitR {
  left: 0px;
  width: 160px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 60px;
}

.reset-bar {
  display: block;
}

.menu-splitR {
  left: 160px;
  padding: 0;
}

div.menu ul li {
  list-style: none;
  width: 320px;
  margin-top: 25px;
  text-align: center;
  font-size: 19px;
  -webkit-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 150ms cubic-bezier(0, 0.995, 0.99, 1);
}

div.menu ul li a {
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 2px;
}

section > div {
  transition: transform 1s;
  transform: translateX(0px);
}

div.menu li.animate {
  font-size: 19px;
  opacity: 1;
  -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}

.menu-splitL li:nth-of-type(1) {
  margin-left: 44px;
  transition-delay: 0.12s;
}

.menu-splitL li.animate:nth-of-type(1) {
  margin-left: 0;
  transition-delay: 0.0s;
}

.menu-splitL li:nth-of-type(2) {
  margin-left: 62px;
  transition-delay: 0.09s;
}

.menu-splitL li.animate:nth-of-type(2) {
  margin-left: 0;
  transition-delay: 0.05s;
}

.menu-splitL li:nth-of-type(3) {
  margin-left: 57px;
  transition-delay: 0.046s;
}

.menu-splitL li.animate:nth-of-type(3) {
  margin-left: 0;
  transition-delay: 0.1s;
}

.menu-splitL li:nth-of-type(4) {
  margin-left: 80px;
  transition-delay: 0.03s;
}

.menu-splitL li.animate:nth-of-type(4) {
  margin-left: 0;
  transition-delay: 0.15s;
}

.menu-splitL li:nth-of-type(5) {
  margin-left: 59px;
  transition-delay: 0.0s;
}

.menu-splitL li.animate:nth-of-type(5) {
  margin-left: 0;
  transition-delay: 0.2s;
}

.menu-splitL li:nth-of-type(6) {
  margin-left: 59px;
  transition-delay: 0.0s;
}

.menu-splitL li.animate:nth-of-type(6) {
  margin-left: 0;
  transition-delay: 0.2s;
}

.menu-splitL li:nth-of-type(7) {
  margin-left: 59px;
  transition-delay: 0.0s;
}

.menu-splitL li.animate:nth-of-type(7) {
  margin-left: 0;
  transition-delay: 0.2s;
}

.menu-splitR li:nth-of-type(1) {
  margin-left: -201px;
  transition-delay: 0.12s;
}

.menu-splitR li.animate:nth-of-type(1) {
  margin-left: -160px;
  transition-delay: 0.0s;
}

.menu-splitR li:nth-of-type(2) {
  margin-left: -220px;
  transition-delay: 0.069s;
}

.menu-splitR li.animate:nth-of-type(2) {
  margin-left: -160px;
  transition-delay: 0.05s;
}

.menu-splitR li:nth-of-type(3) {
  margin-left: -214px;
  transition-delay: 0.06s;
}

.menu-splitR li.animate:nth-of-type(3) {
  margin-left: -160px;
  transition-delay: 0.1s;
}

.menu-splitR li:nth-of-type(4) {
  margin-left: -240px;
  transition-delay: 0.03s;
}

.menu-splitR li.animate:nth-of-type(4) {
  margin-left: -160px;
  transition-delay: 0.15s;
}

.menu-splitR li:nth-of-type(5) {
  margin-left: -217px;
  transition-delay: 0.0s;
}

.menu-splitR li.animate:nth-of-type(5) {
  margin-left: -160px;
  transition-delay: 0.2s;
}

.menu-splitR li:nth-of-type(6) {
  margin-left: -217px;
  transition-delay: 0.0s;
}

.menu-splitR li.animate:nth-of-type(6) {
  margin-left: -160px;
  transition-delay: 0.2s;
}

.menu-splitR li:nth-of-type(7) {
  margin-left: -217px;
  transition-delay: 0.0s;
}

.menu-splitR li.animate:nth-of-type(7) {
  margin-left: -160px;
  transition-delay: 0.2s;
}

/* Menu End */
/*Custom Fonts Start*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?vr5gp4");
  src: url("../fonts/icomoon.eot?vr5gp4#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?vr5gp4") format("truetype"), url("../fonts/icomoon.woff?vr5gp4") format("woff"), url("../fonts/icomoon.svg?vr5gp4#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-size: 14px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.33;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-form-button:before {
  content: "\e900";
}

.icon-scroller:before {
  content: "\e901";
}

.icon-backup:before {
  content: "\e902";
}

.icon-c-panel:before {
  content: "\e903";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-git-support:before {
  content: "\e905";
}

.icon-google-plus:before {
  content: "\e906";
}

.icon-gurantee:before {
  content: "\e907";
}

.icon-heading:before {
  content: "\e908";
}

.icon-latest-tech:before {
  content: "\e909";
}

.icon-pinterest:before {
  content: "\e90a";
}

.icon-scripts:before {
  content: "\e90b";
}

.icon-setup:before {
  content: "\e90c";
}

.icon-support:before {
  content: "\e90d";
}

.icon-twitter:before {
  content: "\e90e";
}

.icon-uptime:before {
  content: "\e90f";
}

/*Custom Fonts End*/
.wow {
  visibility: hidden;
}

.m-t-10 {
  margin-top: 10px;
}

/* Navigation */
.navigation-style {
  background-color: unset;
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
  min-height: unset;
  padding: 0;
}

.navigation-style .header-logo {
  width: 155px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .navigation-style .header-logo {
    margin: 0;
  }
}

.navigation-style .container > .navbar-header.navbar-container {
  width: auto;
}

@media (min-width: 768px) {
  .navigation-style .container > .navbar-header.navbar-container {
    width: 28%;
  }
}

.navigation-style .navigation-top {
  padding-left: 26px;
}

.navigation-style .navigation-top li {
  padding-right: 0;
}

@media (min-width: 992px) {
  .navigation-style .navigation-top li {
    padding-right: 15px;
  }
}

.nav-mobile {
  display: none;
}

@media (min-width: 768px) {
  .nav-mobile .navbar-right {
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .nav-mobile {
    display: block;
  }
}

.screen {
  display: block;
}

@media (min-width: 768px) {
  .screen {
    display: none;
  }
}

.nav > li > a {
  padding: 9px 8px;
}

@media (min-width: 992px) {
  .nav > li > a {
    padding: 10px 15px;
  }
}

.container-style {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

@media (min-width: 768px) {
  .container-style {
    -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
    -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
    transition: background .5s ease-in-out, padding .5s ease-in-out;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.container-style:after {
  content: '';
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
  margin-top: 20px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 768px) {
  .container-style:after {
    -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
    -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
    transition: background .5s ease-in-out, padding .5s ease-in-out;
  }
}

@media (min-width: 768px) {
  .navbar {
    -webkit-transition: background .5s ease-in-out, padding .5s ease-in-out;
    -moz-transition: background .5s ease-in-out, padding .5s ease-in-out;
    transition: background .5s ease-in-out, padding .5s ease-in-out;
  }
}

.top-nav-collapse {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px, rgba(0, 0, 0, 0.1) 0px 1px 0px;
}

.top-nav-collapse hr {
  display: none;
}

.top-nav-collapse .container-style {
  padding-top: 15px;
}

.top-nav-collapse .container-style:after {
  height: 0;
  margin-top: 15px;
}

.navbar-default .navbar-nav > li > a {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 300;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a .active {
  outline: 0;
  color: #ffffff;
  text-shadow: 0 0 1px white;
}

@media (min-width: 1200px) {
  .navbar-default .navbar-nav > li > a {
    font-size: 15px;
  }
}

.navbar-default .navbar-nav.navbar-right > li > a {
  font-weight: 500;
}

.navbar-default .navbar-nav > li > a.active {
  color: #ffffff;
  background-color: unset;
  font-weight: 400;
}

.navbar-default .navbar-nav > li > a.active:focus, .navbar-default .navbar-nav > li > a.active:hover {
  color: #ffffff;
  background-color: unset;
}

.navigation-bar.top-nav-collapse .navbar-nav > li > a.active {
  color: #24aa65 !important;
}

@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.navbar-default .navbar-toggle {
  padding: 0;
  border: 0;
  margin-bottom: 0;
  margin-top: 6px;
}

.hr-line {
  border: none;
  height: 1px;
  background: rgba(255, 255, 255, 0);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, rgba(255, 255, 255, 0));
  -webkit-box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* HEADER */
.header-background {
  background: #24aa65;
  background-image: url("../images/bg-line.png");
  /* fallback */
  background-image: url("../images/bg-line.png"), -moz-linear-gradient(93deg, #19b062 0%, #28ac68 45.923%, #31cb7c 100%);
  background-image: url("../images/bg-line.png"), -webkit-linear-gradient(93deg, #19b062 0%, #28ac68 45.923%, #31cb7c 100%);
  position: relative;
  padding: 0 0 32px;
}

.header-background .header {
  padding-top: 100px;
}

.header-background .container-padding {
  padding-bottom: 24px;
}

@media (min-width: 768px) {
  .header-background .container-padding {
    padding-bottom: 45px;
    padding-top: 45px;
  }
}

.header-background .container-padding .heading-page {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 42px;
}

@media (min-width: 768px) {
  .header-background .container-padding .heading-page {
    font-size: 80px;
  }
}

.header-background .container-padding .sub-content {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .header-background .container-padding .sub-content {
    width: 66%;
  }
}

.header-slider.carousel-indicators {
  display: none;
}

/* ABOUT SECTION */
.style-line {
  width: 130px;
  margin: 4px auto;
  margin-bottom: -17px;
  border: none;
  height: 1px;
  background: rgba(48, 199, 121, 0.23);
  background: -webkit-linear-gradient(left, rgba(48, 199, 121, 0.23), #30c779, rgba(68, 68, 68, 0));
  background: linear-gradient(to right, rgba(48, 199, 121, 0.23), #30c779, rgba(68, 68, 68, 0));
}

.icon-bg-green {
  background: #ffffff !important;
  font-size: 30px;
  color: #30c779;
}

.about-section {
  padding: 70px 0 0 0;
}

@media (min-width: 768px) {
  .about-section {
    padding: 90px 0 105px 0;
  }
}

.about-section .icon-bg {
  color: #24aa65;
  font-size: 22px;
  background: #ffffff;
}

.about-section .heading {
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 500;
  color: #444444;
  text-align: center;
  margin-top: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .about-section .heading {
    font-size: 35px;
  }
}

.about-section .flip-container {
  perspective: 1000px;
  height: 242px;
}

@media (min-width: 768px) {
  .about-section .flip-container {
    height: 180px;
  }
}

.about-section .flip-container:hover .flipper {
  transform: rotateY(180deg);
}

.about-section .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.about-section .front {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  transform: rotateY(0deg);
}

.about-section .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}

.about-section .flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

/* container */
.about-info {
  margin-top: 85px;
}

@media (min-width: 768px) {
  .about-info {
    margin-top: 120px;
  }
}

.about-info .info-box {
  position: relative;
  min-height: 180px;
  border: 2px solid #24aa65;
  border-radius: 4px;
  padding: 15px 30px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 80px;
  text-align: center;
  transition: all 0.5s ease;
}

@media (min-width: 768px) {
  .about-info .info-box {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 768px) {
  .about-info .info-box {
    margin-bottom: 0;
  }
}

.about-info .info-box .content-about {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  display: none;
  color: #ffffff;
}

.about-info .info-box.info-content {
  background-color: #24aa65;
  background-image: url("../images/bg-line.png");
  border-color: transparent;
}

.about-info .info-box.info-content .content-about {
  display: block;
}

.about-info .info-box.info-content .heading-content {
  display: none;
}

.about-info .info-box.info-content .info-line {
  border-top-color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.about-info .info-box.info-content .circle-icon {
  display: none;
}

.about-info .info-box .circle-icon {
  width: 82px;
  height: 82px;
  border-radius: 60%;
  margin: -60px auto 0;
  margin-bottom: 0;
  text-align: center;
  background-color: #24aa65;
  display: block;
  font-size: 45px;
  color: #ffffff;
  line-height: 1.8;
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}

.about-info .info-box .circle-icon .icon-align {
  line-height: 2;
}

.about-info .info-box .info-line {
  width: 55%;
  border-top: 2px solid #24aa65;
  margin: 22px auto;
}

.about-info .info-box h3.heading-content {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: #6e6e6e;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .about-info .info-box h3.heading-content {
    font-size: 16px;
  }
}

@media (min-width: 1050px) {
  .about-info .info-box h3.heading-content {
    font-size: 20px;
  }
}

/* Features Section */
.heading-background {
  background-image: url("../images/sinewave.png");
  position: relative;
  padding: 40px 0;
  background-size: cover;
}

.heading-background .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.62;
  background-color: #3de28d;
}

.heading-background .heading {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.heading-background .heading h2 {
  font-size: 25px;
  font-weight: 500;
  margin-top: 0;
  letter-spacing: 1px;
}

@media (min-width: 768px) {
  .heading-background .heading h2 {
    font-size: 36px;
  }
}

.heading-background .heading .sub-heading p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .heading-background .heading .sub-heading p {
    font-size: 18px;
  }
}

.features {
  padding: 55px 0 20px 0;
}

@media (min-width: 544px) {
  .features {
    padding: 55px 0 25px 0;
  }
}

@media (min-width: 768px) {
  .features {
    padding: 75px 0 35px 0;
  }
}

@media (min-width: 768px) {
  .features {
    padding: 75px 0 55px 0;
  }
}

.features .circle-features {
  font-size: 18px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid #24aa65;
  color: #24aa65;
  float: left;
  text-align: center;
  margin-right: 10px;
  line-height: 1.6;
}

.features .heading h3 {
  font-family: "Montserrat", sans-serif;
  color: #6e6e6e;
  font-size: 18px;
  line-height: 2;
  font-weight: 600;
  margin-top: 0;
}

@media (min-width: 768px) {
  .features .heading h3 {
    font-size: 15px;
    line-height: 2;
  }
}

@media (min-width: 1050px) {
  .features .heading h3 {
    font-size: 24px;
    line-height: 1.4;
  }
}

.features .content {
  width: 90%;
  padding: 5px 0 40px 0;
}

.features .content p {
  font-family: "Open Sans", sans-serif;
  color: #7f7e7e;
  font-weight: 500;
  font-size: 16px;
}

@media (min-width: 768px) {
  .features .content p {
    font-size: 14px;
  }
}

@media (min-width: 1050px) {
  .features .content p {
    font-size: 16px;
  }
}

.feature-block:hover .circle-features {
  background-color: #24aa65;
  color: #ffffff;
}

/* Pricing Section */
.pricing-section {
  margin-top: 50px;
}

@media (min-width: 992px) {
  .pricing-section {
    margin-top: 100px;
  }
}

.pricing-section .content-section, .pricing-section .compare-pricing-plans + .compare-plans {
  text-align: center;
  background-color: #ffffff;
}

.pricing-section .content-section .main-container, .pricing-section .compare-pricing-plans + .compare-plans .main-container {
  width: 100%;
  margin: 16px auto;
}

@media (min-width: 544px) {
  .pricing-section .content-section .main-container, .pricing-section .compare-pricing-plans + .compare-plans .main-container {
    width: 50%;
    float: left;
  }
}

@media (min-width: 992px) {
  .pricing-section .content-section .main-container, .pricing-section .compare-pricing-plans + .compare-plans .main-container {
    width: 25%;
    float: left;
  }
}

.pricing-section .content-section .main-container .green-container, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container {
  background-color: #2bbf71;
  padding: 0 0 25px 0;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.pricing-section .content-section .main-container .green-container.basic-first, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container.basic-first {
  background-color: #24aa65;
}

.pricing-section .content-section .main-container .green-container.professional-first, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container.professional-first {
  background-color: #444444;
}

.pricing-section .content-section .main-container .green-container.enterprise-first, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container.enterprise-first {
  background-color: #373737;
}

.pricing-section .content-section .main-container .green-container .container-1, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container .container-1 {
  padding: 10px;
}

.pricing-section .content-section .main-container .green-container .container-1.starter, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container .container-1.starter {
  background-color: rgba(0, 0, 0, 0.32);
}

.pricing-section .content-section .main-container .green-container .container-1.basic-container-2, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container .container-1.basic-container-2 {
  background-color: rgba(0, 0, 0, 0.38);
}

.pricing-section .content-section .main-container .green-container .container-1.professional-container-2, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container .container-1.professional-container-2 {
  background-color: rgba(0, 0, 0, 0.27);
}

.pricing-section .content-section .main-container .green-container .container-1.enterprise-container-2, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container .container-1.enterprise-container-2 {
  background-color: rgba(0, 0, 0, 0.44);
}

.pricing-section .content-section .main-container .green-container .container-1 .heading, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container .container-1 .heading {
  font-weight: 500;
  font-size: 17px;
  margin: 8px;
}

@media (min-width: 768px) {
  .pricing-section .content-section .main-container .green-container .container-1 .heading, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container .container-1 .heading {
    font-size: 22px;
  }
}

.pricing-section .content-section .main-container .green-container .text-digit, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container .text-digit {
  font-weight: 200;
  font-size: 52px;
  padding-top: 10px;
  letter-spacing: 2px;
}

.pricing-section .content-section .main-container .green-container .month, .pricing-section .compare-pricing-plans + .compare-plans .main-container .green-container .month {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  margin-left: 72px;
  letter-spacing: 0;
  display: block;
}

.pricing-section .content-section .main-container .gray-content-container, .pricing-section .compare-pricing-plans + .compare-plans .main-container .gray-content-container {
  background-color: #f8f8f8;
  padding: 20px 0 35px 0;
}

.pricing-section .content-section .main-container .gray-content-container.basic, .pricing-section .compare-pricing-plans + .compare-plans .main-container .gray-content-container.basic {
  background-color: #f2f2f2;
}

.pricing-section .content-section .main-container .gray-content-container.professional, .pricing-section .compare-pricing-plans + .compare-plans .main-container .gray-content-container.professional {
  background-color: #ebebeb;
}

.pricing-section .content-section .main-container .gray-content-container.enterprise, .pricing-section .compare-pricing-plans + .compare-plans .main-container .gray-content-container.enterprise {
  background-color: #dfdfdf;
}

.pricing-section .content-section .main-container .gray-content-container .content-pricing, .pricing-section .compare-pricing-plans + .compare-plans .main-container .gray-content-container .content-pricing {
  padding-top: 25px;
  padding-left: 0;
  text-align: center;
}

.pricing-section .content-section .main-container .gray-content-container .content-pricing li, .pricing-section .compare-pricing-plans + .compare-plans .main-container .gray-content-container .content-pricing li {
  list-style-type: none;
  font-family: "Open Sans", sans-serif;
  color: #918f8f;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
  padding-bottom: 15px;
  text-align: center;
}

.pricing-section .content-section .reset, .pricing-section .compare-pricing-plans + .compare-plans .reset {
  visibility: hidden;
}

.pricing-section .content-section .container-class, .pricing-section .compare-pricing-plans + .compare-plans .container-class {
  color: #ffffff;
  vertical-align: middle;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
}

.pricing-section .content-section .container-class .standard-block, .pricing-section .compare-pricing-plans + .compare-plans .container-class .standard-block {
  background-color: #187043;
  font-weight: 500;
}

.pricing-section .content-section .container-class .dedicated-block, .pricing-section .compare-pricing-plans + .compare-plans .container-class .dedicated-block {
  background-color: rgba(0, 0, 0, 0.77);
  font-weight: 500;
}

.pricing-section .content-section .container-class .managed-block, .pricing-section .compare-pricing-plans + .compare-plans .container-class .managed-block {
  background-color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}

.pricing-section .content-section .container-class .compare-text-digit, .pricing-section .compare-pricing-plans + .compare-plans .container-class .compare-text-digit {
  font-weight: 200;
  font-size: 52px;
  padding-top: 10px;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
}

.pricing-section .content-section .container-class .compare-text-digit.standard-block-2, .pricing-section .compare-pricing-plans + .compare-plans .container-class .compare-text-digit.standard-block-2 {
  background-color: #2bbf71;
}

.pricing-section .content-section .container-class .compare-text-digit.dedicated-block-2, .pricing-section .compare-pricing-plans + .compare-plans .container-class .compare-text-digit.dedicated-block-2 {
  background-color: rgba(68, 68, 68, 0.89);
}

.pricing-section .content-section .container-class .compare-text-digit.managed-block-2, .pricing-section .compare-pricing-plans + .compare-plans .container-class .compare-text-digit.managed-block-2 {
  background-color: #373737;
}

.pricing-section .content-section .container-class .month-comparing, .pricing-section .compare-pricing-plans + .compare-plans .container-class .month-comparing {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  line-height: 0;
  letter-spacing: 0;
  margin-bottom: 18px;
}

@media (min-width: 992px) {
  .pricing-section .content-section .container-class .month-comparing, .pricing-section .compare-pricing-plans + .compare-plans .container-class .month-comparing {
    margin-left: 72px;
  }
}

.pricing-section .content-section .container-class .feature-heading, .pricing-section .compare-pricing-plans + .compare-plans .container-class .feature-heading {
  background: #575757;
  font-family: "Montserrat", sans-serif !important;
  color: #ffffff !important;
  font-size: 24px !important;
  font-weight: 600 !important;
}

.pricing-section .content-section .container-class .feature-heading .sub-heading-feature, .pricing-section .compare-pricing-plans + .compare-plans .container-class .feature-heading .sub-heading-feature {
  font-size: 22px !important;
  font-weight: 500 !important;
}

.pricing-section .content-section .container-class .feature-heading .name, .pricing-section .compare-pricing-plans + .compare-plans .container-class .feature-heading .name {
  color: #24aa65 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  margin-left: 5px;
}

.pricing-section .content-section .table > thead > tr > th, .pricing-section .compare-pricing-plans + .compare-plans .table > thead > tr > th {
  border-bottom: 0;
  border-top: 0;
}

.pricing-section .content-section table > tbody > tr > td, .pricing-section .compare-pricing-plans + .compare-plans table > tbody > tr > td, .pricing-section .content-section .table > tbody > tr > th, .pricing-section .compare-pricing-plans + .compare-plans .table > tbody > tr > th, .pricing-section .content-section .table > tfoot > tr > td, .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > td, .pricing-section .content-section .table > tfoot > tr > th, .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > th, .pricing-section .content-section .table > thead > tr > td, .pricing-section .compare-pricing-plans + .compare-plans .table > thead > tr > td, .pricing-section .content-section .table > thead > tr > th, .pricing-section .compare-pricing-plans + .compare-plans .table > thead > tr > th {
  border-top: 0;
  padding: 20px 0 20px 0;
  width: 10%;
}

.pricing-section .content-section table > tbody > tr > td:first-child, .pricing-section .compare-pricing-plans + .compare-plans table > tbody > tr > td:first-child, .pricing-section .content-section .table > tbody > tr > th:first-child, .pricing-section .compare-pricing-plans + .compare-plans .table > tbody > tr > th:first-child, .pricing-section .content-section .table > tfoot > tr > td:first-child, .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > td:first-child, .pricing-section .content-section .table > tfoot > tr > th:first-child, .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > th:first-child, .pricing-section .content-section .table > thead > tr > td:first-child, .pricing-section .compare-pricing-plans + .compare-plans .table > thead > tr > td:first-child, .pricing-section .content-section .table > thead > tr > th:first-child, .pricing-section .compare-pricing-plans + .compare-plans .table > thead > tr > th:first-child {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #6b6969;
  font-weight: 600;
  vertical-align: middle;
}

.pricing-section .content-section table > tbody > tr > td:first-child, .pricing-section .compare-pricing-plans + .compare-plans table > tbody > tr > td:first-child, .pricing-section .content-section .table > tbody > tr > th:first-child, .pricing-section .compare-pricing-plans + .compare-plans .table > tbody > tr > th:first-child, .pricing-section .content-section .table > tfoot > tr > td:first-child, .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > td:first-child, .pricing-section .content-section .table > tfoot > tr > th:first-child, .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > th:first-child, .pricing-section .content-section .table > thead > tr > td:first-child, .pricing-section .compare-pricing-plans + .compare-plans .table > thead > tr > td:first-child {
  background-color: rgba(255, 255, 255, 0.17);
  text-transform: uppercase;
}

.pricing-section .content-section table > tbody > tr > td:nth-child(3), .pricing-section .compare-pricing-plans + .compare-plans table > tbody > tr > td:nth-child(3), .pricing-section .content-section .table > tbody > tr > th:nth-child(3), .pricing-section .compare-pricing-plans + .compare-plans .table > tbody > tr > th:nth-child(3), .pricing-section .content-section .table > tfoot > tr > td:nth-child(3), .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > td:nth-child(3), .pricing-section .content-section .table > tfoot > tr > th:nth-child(3), .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > th:nth-child(3), .pricing-section .content-section .table > thead > tr > td:nth-child(3), .pricing-section .compare-pricing-plans + .compare-plans .table > thead > tr > td:nth-child(3) {
  background-color: rgba(150, 150, 150, 0.09);
}

.pricing-section .content-section table > tbody > tr > td:nth-child(4), .pricing-section .compare-pricing-plans + .compare-plans table > tbody > tr > td:nth-child(4), .pricing-section .content-section .table > tbody > tr > th:nth-child(4), .pricing-section .compare-pricing-plans + .compare-plans .table > tbody > tr > th:nth-child(4), .pricing-section .content-section .table > tfoot > tr > td:nth-child(4), .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > td:nth-child(4), .pricing-section .content-section .table > tfoot > tr > th:nth-child(4), .pricing-section .compare-pricing-plans + .compare-plans .table > tfoot > tr > th:nth-child(4), .pricing-section .content-section .table > thead > tr > td:nth-child(4), .pricing-section .compare-pricing-plans + .compare-plans .table > thead > tr > td:nth-child(4) {
  background-color: rgba(248, 247, 247, 0.08);
}

.pricing-section .content-section .table-striped > tbody > tr:nth-of-type(odd), .pricing-section .compare-pricing-plans + .compare-plans .table-striped > tbody > tr:nth-of-type(odd) {
  background: #dadada;
}

.pricing-section .content-section .table-striped > tbody > tr:nth-of-type(even), .pricing-section .compare-pricing-plans + .compare-plans .table-striped > tbody > tr:nth-of-type(even) {
  background: rgba(158, 158, 158, 0.22);
}

.table-compare td, .table-compare th {
  padding: 20px 10px !important;
}

.text-important {
  font-weight: 600;
}

.compare-plans .lnr:before {
  font-size: 22px;
}

/* Compare Pricing Plans Section */
.compare-pricing-plans {
  margin: 40px 0 0 0;
}

.compare-pricing-plans .icon-bg {
  background: #ffffff !important;
  font-size: 22px;
  color: #24aa65 !important;
}

.compare-pricing-plans .heading {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 500;
  color: #24aa65;
  text-align: center;
  padding: 0 0 8px 0;
  text-transform: uppercase;
}

.compare-pricing-plans .style-line {
  width: 144px;
  margin: -1px auto;
  margin-bottom: -16px;
  border: none;
  height: 1px;
  background: rgba(48, 199, 121, 0.23);
  background: -webkit-linear-gradient(left, rgba(48, 199, 121, 0.23), #30c779, rgba(68, 68, 68, 0));
  background: linear-gradient(to right, rgba(48, 199, 121, 0.23), #30c779, rgba(68, 68, 68, 0));
}

.compare-pricing-plans + .compare-plans {
  margin-top: 40px;
}

.compare-pricing-plans + .compare-plans .features {
  margin-top: 4px;
}

.compare-pricing-plans + .compare-plans .features .container-heading {
  background-color: #575757;
  padding-bottom: 4px;
}

.compare-pricing-plans + .compare-plans .features .container-heading .plan-features {
  color: #ffffff;
  text-transform: uppercase;
  line-height: 0;
  margin: 47px 0 0 0 !important;
  padding-bottom: 0 !important;
}

.compare-pricing-plans + .compare-plans .features .container-heading .plan-features-2 {
  color: #24aa65;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 36px;
  font-size: 17px;
  font-weight: 500;
}

.compare-pricing-plans + .compare-plans .gray-container-padding {
  padding: 0 !important;
}

.compare-pricing-plans + .compare-plans .ul_style {
  text-align: left !important;
  padding-top: 0 !important;
}

.compare-pricing-plans + .compare-plans .ul_style li {
  font-weight: 600 !important;
  padding: 13px 0 13px 0 !important;
  color: #6b6969 !important;
}

.compare-pricing-plans + .compare-plans .ul_style li:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.11);
}

.compare-pricing-plans + .compare-plans .ul_style li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

/* Section Get started */
.get-started {
  z-index: 1;
  position: relative;
  background-image: url("../images/get-started.png");
  background-size: cover;
  width: 85%;
  padding: 25px 0;
  margin: 0 auto;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .get-started {
    padding: 45px 0;
    margin-top: 60px;
  }
}

.get-started .get-started-title {
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  font-size: 21px;
  font-weight: 200;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
}

@media (min-width: 768px) {
  .get-started .get-started-title {
    text-align: left;
    font-size: 35px;
    margin-top: 1px;
  }
}

.get-started .sub-heading {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  color: #ffffff;
  line-height: 0;
  font-style: italic;
  text-align: center;
  font-weight: 300;
}

@media (min-width: 544px) {
  .get-started .sub-heading {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .get-started .sub-heading {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .get-started .sub-heading {
    font-size: 19px;
  }
}

/* Testimonial */
.testimonials {
  background-color: #f4f4f4;
  margin-top: -75px;
  /* Caraousal */
}

.testimonials .icon-bg {
  background: #f4f4f4 !important;
  font-size: 22px;
  color: #24aa65;
}

.testimonials .container-padding {
  padding: 100px 0 50px 0;
}

.testimonials .container-padding .heading {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 500;
  color: #24aa65;
  text-align: center;
  padding: 0 0 8px 0;
  text-transform: uppercase;
}

.testimonials .container-padding .style-line {
  width: 130px;
  margin: 16px auto;
  margin-bottom: -22px;
  border: none;
  height: 1px;
  background: rgba(48, 199, 121, 0.23);
  background: -webkit-linear-gradient(left, rgba(48, 199, 121, 0.23), #30c779, rgba(68, 68, 68, 0));
  background: linear-gradient(to right, rgba(48, 199, 121, 0.23), #30c779, rgba(68, 68, 68, 0));
  display: block;
}

.testimonials .carousel-indicators li {
  background-color: #444444;
  border-radius: 0;
  transform: skew(-10deg);
  border: none;
}

.testimonials .carousel-indicators .active {
  background-color: #24aa65;
}

.testimonials .carousel {
  width: 100%;
  padding: 80px 30px 50px;
  margin: 0 auto;
  overflow: hidden;
}

@media (min-width: 768px) {
  .testimonials .carousel {
    width: 80%;
  }
}

@media (min-width: 1050px) {
  .testimonials .carousel {
    width: 60%;
  }
}

.testimonials .carousel-inner {
  overflow: unset;
}

.testimonials .carousel-inner > .carousel-item {
  padding: 0 30px;
  min-height: 250px;
}

.testimonials .carousel-inner > .carousel-item {
  border: 1px solid #6b6969;
}

.testimonials .carousel-inner > .carousel-item > a > img, .testimonials .carousel-inner > .carousel-item > img {
  border: 1px solid #6b6969;
  margin-top: -63px;
}

.testimonials .carousel-caption {
  position: unset !important;
  font-family: "Open Sans", sans-serif;
  text-shadow: unset;
}

.testimonials .carousel-caption.name {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #24aa65;
  font-weight: 500;
  line-height: 4px;
  padding-bottom: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonials .carousel-caption.designation {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #888888;
  font-weight: 400;
  line-height: 1.6;
  padding: 0;
}

.testimonials .carousel-caption.message {
  text-align: center;
  color: #6e6e6e;
  font-size: 13px;
}

/* Clients */
.clients {
  padding: 30px 0;
}

.clients .clients-container {
  display: flex;
  width: 80%;
  margin: 0 auto;
}

.clients .clients-container .client-logo {
  flex: 1;
}

/* contact */
.contact-section {
  background-color: #373737;
  padding: 20px 0 78px 0;
}

.contact-section .icon-bg {
  background: #373737 !important;
  font-size: 22px;
}

.contact-section .icon-heading:before {
  color: #ffffff;
}

.contact-section .heading {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  padding: 30px 0 60px 0;
  text-transform: uppercase;
}

.contact-section .reset-mobile {
  text-align: center;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .contact-section .reset-mobile {
    text-align: unset;
  }
}

.contact-section .style-line {
  width: 147px;
  margin: 16px auto;
  margin-bottom: -22px;
  border: none;
  height: 1px;
  background: rgba(48, 199, 121, 0);
  background: -webkit-linear-gradient(left, rgba(48, 199, 121, 0), #ffffff, rgba(68, 68, 68, 0));
  background: linear-gradient(to right, rgba(48, 199, 121, 0), #ffffff, rgba(68, 68, 68, 0));
  display: block;
}

.contact-section .contact-info {
  background-color: #212121;
  padding: 25px 0 5px 0;
}

@media (min-width: 768px) {
  .contact-section .contact-info {
    display: flex;
    width: 80%;
    padding: 25px 0 25px 0;
    margin: 0 auto;
    margin-bottom: 35px;
  }
}

.contact-section .contact-info .mobile-device {
  margin-bottom: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .contact-section .contact-info .mobile-device {
    margin: 0 auto;
    text-align: left;
  }
}

.contact-section .contact-info .style {
  color: #24aa65;
  font-size: 17px;
}

.contact-section .contact-info .fa-home {
  font-size: 20px;
}

.contact-section .contact-info .heading-info {
  font-family: "Montserrat", sans-serif;
  color: #24aa65;
  font-size: 17px;
  font-weight: 400;
  margin-left: 10px;
}

.contact-section .contact-info .address-content {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  margin-top: 5px;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
}

.contact-section .description {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #ffffff;
  width: 85%;
  margin: 0 auto;
  text-align: center;
  padding: 20px 0 50px 0;
  font-weight: 300;
}

@media (min-width: 992px) {
  .contact-section .description {
    width: 65%;
    padding: 25px 0 80px 0;
  }
}

#map {
  height: 250px;
  width: 100%;
  background-color: #4c4c4c;
}

@media (min-width: 768px) {
  #map {
    height: 400px;
  }
}

.infoBox {
  font-family: "Montserrat", sans-serif;
  background: #24aa65;
  padding: 10px;
  color: #ffffff;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
}

.infoBox .pointer-heading {
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
}

.infoBox .map-address {
  font-size: 12px;
  letter-spacing: 1px;
}

.infoBox img {
  visibility: hidden;
}

.infoBox:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-top-color: #24aa65;
  border-width: 15px;
  margin-left: -15px;
}

/* SUBSCRIBE */
@media (min-width: 1200px) {
  #subscribe .container {
    width: 1024px;
  }
}

#subscribe .heading-background .heading {
  text-align: left;
}

#subscribe .heading-background .heading h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 33px;
}

#subscribe .heading-background .heading .sub-heading p {
  font-family: "Open Sans", sans-serif;
  font-size: 19px;
  font-style: italic;
  text-transform: initial;
}

/* FOOTER */
.footer {
  background-color: #262525;
  padding: 65px 0 60px;
}

.footer .img-logo {
  width: 135px;
}

.footer .post {
  width: 55px;
  float: left;
}

.footer .heading {
  padding: 0;
  list-style: none;
}

.footer .heading li {
  font-family: "Montserrat", sans-serif;
}

.footer .heading li a {
  font-size: 14px;
  padding: 4px 0 8px 0;
  color: #ffffff;
  font-weight: 300;
}

.footer .heading li .heading-style {
  float: left;
  margin-left: 10px;
  color: #ffffff;
  font-size: 13px;
  line-height: 1;
  font-weight: 300;
}

.footer .heading li p.about-text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 12px 0 8px 0;
  width: 70%;
  color: #ffffff;
}

.footer .heading li:first-child {
  color: #24aa65;
  padding-bottom: 12px;
  font-size: 18px;
  font-weight: 500;
}

.footer .heading li .social-icons a {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  margin-right: 6px;
  padding: 10px 6px;
  transition: all 0.5s ease;
}

.footer .heading li .social-icons a i {
  font-size: 18px;
}

.footer .heading li .social-icons a:hover, .footer .heading li .social-icons a:focus {
  text-decoration: none;
  border: 2px solid #24aa65;
  color: #24aa65;
}

.footer .recent-post {
  list-style: none;
  padding: 0;
}

.footer .recent-post li {
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid #ffffff;
  padding: 16px 0 15px 0;
  color: #ffffff;
  font-size: 13px;
  line-height: 1;
  font-weight: 300;
}

.footer .recent-post li:first-child {
  border-bottom: 0;
  color: #24aa65;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
}

.footer .recent-post li p {
  margin-bottom: 5px;
}

.footer .recent-post li p a {
  font-size: 14px;
  overflow: hidden;
  padding-left: 8px;
  padding-top: 2px;
  color: #24aa65;
}

.footer .recent-post li .days {
  color: #838584;
  font-weight: 300;
  margin-left: 10px;
  line-height: 2;
  font-size: 13px;
}

.footer .recent-post li .content-post {
  font-family: "Open Sans", sans-serif;
  margin-left: 9px;
  font-size: 13px;
}

.footer .clear {
  clear: both;
}

@media (min-width: 992px) {
  .footer .clear {
    clear: none;
  }
}

.footer-bottom {
  background-color: #000;
  padding: 15px;
  text-align: center;
}

.footer-bottom p {
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #24aa65;
}

.footer-bottom p .designed {
  color: #ffffff;
  font-size: 14px;
}

.back-top {
  visibility: hidden;
  color: #fff;
  font-size: 18px;
  text-align: center;
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.71);
  line-height: 1.8;
  border: 1px solid #2d2d2d;
  transition: all 0.5s ease;
}

.back-top:hover, .back-top:focus {
  opacity: 0.8;
  color: #acacac;
  text-decoration: none;
}

@media (min-width: 544px) {
  .back-top {
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 1.9;
  }
}

/* Modal */
.modal-header {
  border: 0;
}

.modal-footer {
  border: 0;
}

.model-form-heading {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 21px;
  color: #444444;
}

.modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.modal-body .form-sub-content {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  text-align: center;
  margin: 5px 0;
  color: #5e5d5d;
}

.modal-body .form-modal-container {
  margin: 30px 20px 30px 0;
  text-align: center;
}

.modal-body .form-control.edit-form-input {
  font-family: "Open Sans", sans-serif;
  border-radius: 3px;
  box-shadow: none;
}

.modal-body .account {
  text-align: left;
  margin-top: 15px;
  font-size: 12px;
  font-weight: 600;
  color: #5d5d5d;
}

.modal-body .account a {
  display: inline-block;
  margin-left: 3px;
  font-weight: 600;
  color: coral;
}

.modal-body .login-img-container {
  margin: 10px 20px 55px 20px;
}

@media (min-width: 768px) {
  .modal-body .login-img-container {
    margin: 55px 0 55px 20px;
  }
}

.modal-body .login-img-container .img-reset {
  width: 100%;
}

.forget-password {
  text-align: left;
  margin-top: -8px;
  margin-bottom: 9px;
  font-size: 11px;
  color: #565656;
}

.forget-password:hover, .forget-password:focus, .forget-password:active {
  color: #24aa65;
}

.login-form, .signup-form, .forget-password-field {
  display: none;
  padding-bottom: 11px;
}

#temaplate {
  display: none;
  height: 0;
  width: 0;
}

#error_contact {
  max-width: 400px;
}

/* General Reset Start*/
html, body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

:focus, a:focus {
  outline: none;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: none;
  outline-offset: 0;
}

a {
  display: block;
}

.form-container {
  position: relative;
  color: #ffffff;
}

.form-container input {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 300;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #ffffff;
  background-color: transparent;
  line-height: 3;
  outline: 0;
  padding-left: 5px;
}

.form-container input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}

.form-container input::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}

.form-container input:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}

.form-container input:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}

.form-container input:focus {
  outline: 0;
}

.form-container .subscriber-error {
  border-bottom-color: #ff5a5a;
}

.form-container .subscriber-error::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ff5a5a;
  text-transform: uppercase;
}

.form-container .subscriber-error::-moz-placeholder {
  /* Firefox 19+ */
  color: #ff5a5a;
  text-transform: uppercase;
}

.form-container .subscriber-error:-ms-input-placeholder {
  /* IE 10+ */
  color: #ff5a5a;
  text-transform: uppercase;
}

.form-container .subscriber-error:-moz-placeholder {
  /* Firefox 18- */
  color: #ff5a5a;
  text-transform: uppercase;
}

.form-container button {
  position: absolute;
  top: 0;
  right: 0;
  outline: 0;
  border: 0;
  background: transparent;
}

.form-container button span {
  color: #ffffff;
  font-size: 30px;
  transition: all 0.5s ease;
}

.form-container button:hover span {
  color: #2c6083;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

/* General Reset End*/
